import React from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import * as Yup from 'yup';

const CashStep = () => {
    return (
        <>
            <Box sx={{ marginBottom: 4 }}>
                <Typography variant="h6" component="h1" fontWeight="bold" gutterBottom>
                    Let's get started preparing your financial roadmap.
                </Typography>

                <Typography variant="body1">
                    Take the first step toward financial clarity and confidence. This guided wizard will take around 5 minutes to gather the neccesary inforamation to compile your <b>financial roadmap.</b>
                </Typography>

            </Box>

        </>
    );
};

export default CashStep;



export const validationSchema = Yup.object({

});
