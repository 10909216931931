import React from 'react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

const PersonalStep = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name="name"
          component={TextField}
          label="Name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name="surname"
          component={TextField}
          label="Surname"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name="email"
          component={TextField}
          label="Email"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name="dob"
          component={TextField}
          label="Date of Birth"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name="retirementAge"
          component={TextField}
          label="Retirement Age"
          type="number"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name="occupation"
          component={TextField}
          label="Occupation"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name="incomePerYear"
          component={TextField}
          label="Income Per Year"
          type="number"
        />
      </Grid>
    </Grid>
  );
};

export default PersonalStep;




export const validationSchema = Yup.object({
  // Personal
  name: Yup.string().required('Name is required'),
  surname: Yup.string().required('Surname is required'),
  email: Yup.string().required('Email is required'),
  dob: Yup.date().required('Date of Birth is required'),
  retirementAge: Yup.number().positive().integer().required('Retirement Age is required'),
  occupation: Yup.string().required('Occupation is required'),
  incomePerYear: Yup.number().positive().required('Income Per Year is required'),
  
});
