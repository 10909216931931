import React from "react";
import { Container, Card, CardContent, Typography, Box, Grid, Avatar } from "@mui/material";
import { Wizard } from "./Wizard.js";
import PersonalStep, { validationSchema as personalStepValidationSchema } from "./forms/steps/PersonalStep.js";
import ExpenditureStep, { validationSchema as expenditureStepValidationSchema } from "./forms/steps/ExpenditureStep.js";
import PensionStep, { validationSchema as pensionStepValidationSchema } from "./forms/steps/PensionStep.js";
import StatePensionStep, { validationSchema as statePensionStepValidationSchema } from "./forms/steps/StatePensionStep.js";
import InvestmentsStep, { validationSchema as investmentsStepValidationSchema } from "./forms/steps/InvestmentsStep.js";
import CashStep, { validationSchema as cashStepValidationSchema } from "./forms/steps/CashStep.js";
import IntroStep, { validationSchema as introStepValidationSchema } from "./forms/steps/IntroStep.js"
import axios from 'axios'
import stringify from 'json-stringify-pretty-compact';

const App = () => {
    const initialValues = {
        // Personal
        name: "",
        surname: "",
        email: "",
        dob: "",
        retirementAge: "",
        occupation: "",
        incomePerYear: "",
        // Expenditure
        annualEssentialSpending: "",
        annualDiscretionarySpending: "",
        // Personal Pension
        currentPensionValue: "",
        pensionType: "",
        annualContributions: {
            employee: { type: "percentage", value: "" },
            employer: { type: "percentage", value: "" },
        },
        annualGrowthRate: 4,
        annualFees: 0.5,
        // State Pension
        fullNIContribution: false,
        niContributionYears: "",
        // Investments
        investmentCurrentValue: "",
        investmentAnnualContributions: "",
        investmentAnnualGrowthRate: 4,
        investmentAnnualFees: 1,
        // Cash
        cashCurrentValue: "",
        cashInterestRate: 2,
    };

    const handleSubit = async (values) => {

        try {
            // Prepare the data for submission
            const formData = {
                title: `${values.name} ${values.surname} ${values.email}`,
                content: stringify(values, {
                    indent: 2,
                    maxLength: 80
                  })
            };

            const response = await axios.post('https://app.pleniry.com/api/form-responses/post', formData);

            alert("Form submitted successfully")
            console.log('Form submitted successfully:', response.data);
        } catch (error) {
            alert("Error submitting form")
            console.error('Error submitting form:', error);

            // Set an error status

        } finally {

        }
    }

    return (
        <Box sx={{ bgcolor: "#e6f2ff", minHeight: "100vh", paddingY: 5 }}>
            <Container maxWidth="lg">
                <Grid sx={{
                    bgcolor: "#e6f2ff",
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center", // This centers the content vertically
                    py: 5 // Equivalent to paddingY: 5
                }} container spacing={4}>
                    <Grid item xs={12} md={12}>
                        <Card sx={{ bgcolor: "white", boxShadow: 3, borderRadius: 2 }}>
                            <CardContent sx={{ padding: 4 }}>
                                <Wizard initialValues={initialValues} onSubmit={handleSubit}>
                                    <IntroStep validationSchema={introStepValidationSchema} label="Intro" buttonText="Let's go!" noop="true" />
                                    <PersonalStep validationSchema={personalStepValidationSchema} label="Personal Information" />
                                    <ExpenditureStep validationSchema={expenditureStepValidationSchema} label="Expenditure" />
                                    <PensionStep validationSchema={pensionStepValidationSchema} label="Personal Pension" />
                                    <StatePensionStep validationSchema={statePensionStepValidationSchema} label="State Pension" />
                                    <InvestmentsStep validationSchema={investmentsStepValidationSchema} label="Investments" />
                                    <CashStep validationSchema={cashStepValidationSchema} label="Cash" buttonText="Finish" />
                                </Wizard>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default App;