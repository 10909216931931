import React from 'react';
import { Grid, FormControlLabel } from '@mui/material';
import { Field } from 'formik';
import { TextField, Switch } from 'formik-material-ui';
import * as Yup from 'yup';

const StatePensionStep = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Field
              component={Switch}
              type="checkbox"
              name="fullNIContribution"
            />
          }
          label="Will you have contributed National Insurance for 35 years at retirement?"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="niContributionYears"
          component={TextField}
          label="Total years of National Insurance contributions at retirement"
          type="number"
        />
      </Grid>
    </Grid>
  );
};

export default StatePensionStep;



export const validationSchema = Yup.object({

  // State Pension
  fullNIContribution: Yup.boolean().required('Full NI Contribution information is required'),
  niContributionYears: Yup.number().when('fullNIContribution', {
    is: false,
    then: Yup.number().positive().integer().max(35).required('NI Contribution Years is required when not full contribution'),
  }),

});
