import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import { Field } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import * as Yup from 'yup';

const PensionStep = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name="currentPensionValue"
          component={TextField}
          label="Current Pension Value"
          type="number"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name="pensionType"
          component={Select}
          label="Pension Type"
        >
          <MenuItem value="Personal Pension">Personal Pension</MenuItem>
          <MenuItem value="SIPP">SIPP</MenuItem>
          <MenuItem value="Group Pension">Group Pension</MenuItem>
          <MenuItem value="DB">DB</MenuItem>
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name="annualContributions.employee.type"
          component={Select}
          label="Employee Contribution Type"
        >
          <MenuItem value="percentage">Percentage of Salary</MenuItem>
          <MenuItem value="fixed">Fixed Amount</MenuItem>
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name="annualContributions.employee.value"
          component={TextField}
          label="Employee Contribution Value"
          type="number"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name="annualContributions.employer.type"
          component={Select}
          label="Employer Contribution Type"
        >
          <MenuItem value="percentage">Percentage of Salary</MenuItem>
          <MenuItem value="fixed">Fixed Amount</MenuItem>
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name="annualContributions.employer.value"
          component={TextField}
          label="Employer Contribution Value"
          type="number"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name="annualGrowthRate"
          component={TextField}
          label="Annual Growth Rate (%)"
          type="number"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name="annualFees"
          component={TextField}
          label="Annual Fees (%)"
          type="number"
        />
      </Grid>
    </Grid>
  );
};

export default PensionStep;


export const validationSchema = Yup.object({

  // Personal Pension
  currentPensionValue: Yup.number().min(0).required('Current Pension Value is required'),
  pensionType: Yup.string().oneOf(['Personal Pension', 'SIPP', 'Group Pension', 'DB']).required('Pension Type is required'),
  annualContributions: Yup.object({
    employee: Yup.object({
      type: Yup.string().oneOf(['percentage', 'fixed']).required('Employee contribution type is required'),
      value: Yup.number().min(0).required('Employee contribution value is required'),
    }),
    employer: Yup.object({
      type: Yup.string().oneOf(['percentage', 'fixed']).required('Employer contribution type is required'),
      value: Yup.number().min(0).required('Employer contribution value is required'),
    }),
  }),
  annualGrowthRate: Yup.number().min(0).max(100),
  annualFees: Yup.number().min(0).max(100),
  

});
