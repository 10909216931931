import React from 'react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

const ExpenditureStep = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name="annualEssentialSpending"
          component={TextField}
          label="Annual Essential Spending"
          type="number"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name="annualDiscretionarySpending"
          component={TextField}
          label="Annual Discretionary Spending"
          type="number"
        />
      </Grid>
    </Grid>
  );
};

export default ExpenditureStep;



export const validationSchema = Yup.object({

  // Expenditure
  annualEssentialSpending: Yup.number().positive().required('Annual Essential Spending is required'),
  annualDiscretionarySpending: Yup.number().positive().required('Annual Discretionary Spending is required'),

});
