import React, { useState, useEffect } from "react";
import { Form } from "formik";
import { Button, Stack, Step, StepLabel, Stepper, Typography, Grid, Box, Avatar } from "@mui/material";
import axios from "axios";
import { Formik, useFormikContext } from "formik";
import { validationSchema } from "./validationSchema";
import {Nav} from './forms/nav'


export const Wizard = ({ children, initialValues, onSubmit }) => {
  const stepsArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentStep = stepsArray[step];
  const stepLabel = stepsArray[step].props.label

  return (
    <Grid container spacing={3}>

      {/* Left column for steps */}
      <Grid item xs={12} md={3}>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar
            src="/logo.png"
            alt="Pleniry.com Logo"
            sx={{ width: 50, height: 50, marginRight: 2 }}
          />
          <Typography variant="h5" component="h2">
            Pleniry.com
          </Typography>
        </Box>
        <Stepper activeStep={step} orientation="vertical">
          {stepsArray.map((child, index) => (
            <Step key={child.props.label} completed={step > index}>
              <StepLabel>{child.props.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>

      <Grid item xs={12} md={9}>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Step {step + 1} / {stepsArray.length} <b>{stepLabel}</b>
        </Typography>

        <hr />
        <br />

        <Formik
          initialValues={initialValues}
          validationSchema={currentStep.props.validationSchema}
          onSubmit={async (values, actions) => {
            onSubmit(values)
          }}
        >
          <Form>
            {currentStep}
            <Nav step={currentStep} stepIndex={step} steps={stepsArray} onSubmit={onSubmit} setStep={setStep} />
          </Form>
        </Formik>

      </Grid>
    </Grid>
  );
};